import React, { useEffect } from 'react';
import GoogleAnalytics from 'react-ga';

const withTracker = (WrappedComponent, pathOverride = null, options = {}) => {
  const trackPage = page => {
    GoogleAnalytics.set({
      page,
      ...options
    });
    GoogleAnalytics.pageview(page);
  };

  const HOC = props => {
    useEffect(() => trackPage(pathOverride || props.location.pathname), [
      props.location.pathname
    ]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withTracker;
